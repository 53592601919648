<template>
  <v-card class="card-seo px-5 pt-5 pb-3" flat>
    <v-card-title class="font-weight-bold pa-0 mb-4"
      >Thông tin SEO</v-card-title
    >
    <!-- Start: Title -->
    <div>
      <div class="font-weight-bold mb-2">Tiêu đề trang</div>
      <tp-text-field
        v-model="post.seo_data.title"
        counter
        maxlength="70"
        placeholder="Nhập tiêu đề trang"
        validate="required|max:120"
      ></tp-text-field>
    </div>
    <!-- End: Title -->
    <!-- Start: Description -->
    <div>
      <div class="font-weight-bold mb-2">Mô tả trang</div>
      <tp-textarea
        v-model="post.seo_data.description"
        counter
        maxlength="200"
        placeholder="Nhập mô tả trang"
        validate="required|max:200"
      ></tp-textarea>
    </div>
    <!-- End: Description -->
    <!-- Start: Keywords -->
    <div>
      <div class="font-weight-bold mb-2">Từ khóa</div>
      <tp-combobox
        v-model="post.seo_data.tags"
        deletable-chips
        placeholder="Nhập từ khóa sản phẩm"
        multiple
        small-chips
        validate="required|min:1"
      >
      </tp-combobox>
    </div>
    <!-- End: Keywords -->
    <!-- Start: Preview -->
    <div class="preview grey lighten-5 rounded py-6 mb-5">
      <div class="preview-container">
        <a class="link">
          <div class="link-sub">
            <cite>
              thinkpro.vn<span>
                <span>
                  › tin-tuc › {{ convertStringToSlug(postTitle) }}</span
                ></span
              >
            </cite>
          </div>
          <div class="snippet-title">
            <span ref="title" v-text="metaTitle"></span>
          </div>
          <div class="link-title">
            <span>{{
              this.post.seo_data.title
                ? this.post.seo_data.title
                : "Tiêu đề trang chưa được đặt"
            }}</span>
          </div>
        </a>
        <div class="description">
          <span class="description-content">
            <span class="time"
              >{{ formatDate(this.post.published_at) }} —
            </span>
            <span>{{
              this.post.seo_data.description
                ? this.post.seo_data.description
                : "Chưa có mô tả"
            }}</span>
          </span>
        </div>
      </div>
      <div class="preview-src">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 272 92"
            width="59"
            height="20"
          >
            <path
              fill="#EA4335"
              d="M115.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18C71.25 34.32 81.24 25 93.5 25s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44S80.99 39.2 80.99 47.18c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"
            />
            <path
              fill="#FBBC05"
              d="M163.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"
            />
            <path
              fill="#4285F4"
              d="M209.75 26.34v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"
            />
            <path fill="#34A853" d="M225 3v65h-9.5V3h9.5z" />
            <path
              fill="#EA4335"
              d="M262.02 54.48l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"
            />
            <path
              fill="#4285F4"
              d="M35.29 41.41V32H67c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C16.32 69.35.36 53.89.36 34.91.36 15.93 16.32.47 35.3.47c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65l-22.49.01z"
            />
          </svg>
        </span>
      </div>
    </div>
    <!-- End: Preview -->
    <!-- Start: Yoast SEO -->
    <div>
      <div class="font-weight-bold mb-2">Đánh giá nội dung</div>
      <ContentAssessor
        locale="en_US"
        :title="metaTitle"
        :title-width="titleWidth"
        :description="metaDescription"
        :url="`noi-dung/${convertStringToSlug(postTitle)}`"
        :text="postContent"
      />
      <div class="font-weight-bold mb-2 mt-5">Đánh giá SEO</div>
      <v-tabs v-model="tab">
        <v-tab v-for="item in items" :key="item.title">{{ item.title }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in items" :key="item.title">
          <div class="mb-2 mt-5">Focus Keyword</div>
          <v-textarea
            v-model="item.keyword"
            class="mx-2"
            rows="1"
            label="keyword"
          ></v-textarea>
          <SeoAssessor
            locale="en_US"
            :keyword="item.keyword"
            :title="metaTitle"
            :title-width="titleWidth"
            :description="metaDescription"
            :url="`noi-dung/${convertStringToSlug(postTitle)}`"
            :text="postContent"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <!-- Start: Yoast SEO -->
  </v-card>
</template>

<script>
import * as VueYoast from "vue-yoast";
import StringFunction from "@/core/utils/string";
import "vue-yoast/dist/vue-yoast.css";

const { ContentAssessor, SeoAssessor } = VueYoast;

export default {
  components: {
    ContentAssessor,
    SeoAssessor
  },
  data() {
    return {
      tab: null,
      titleWidth: 0,
      items: [
        {
          title: "Focus Keyword 1",
          keyword: ""
        },
        {
          title: "Focus Keyword 2",
          keyword: ""
        },
        {
          title: "Focus Keyword 3",
          keyword: ""
        }
      ],
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    };
  },
  computed: {
    post() {
      return this.$store.getters["POST/post"];
    },
    postTitle() {
      return this.post?.title || "";
    },
    postContent() {
      return this.post?.content || "";
    },
    focusKeyword() {
      if (!this.post?.seo_data?.tags?.length) {
        return "";
      }
      return this.post.seo_data.tags.join(", ");
    },
    metaTitle() {
      return this.post?.seo_data?.title ? this.post.seo_data.title : "";
    },
    metaDescription() {
      return this.post?.seo_data?.description
        ? this.post.seo_data.description
        : "";
    }
  },
  watch: {
    post: {
      handler() {
        this.onTitleUpdated();
      },
      deep: true
    }
  },
  mounted() {
    this.onTitleUpdated();
  },
  methods: {
    convertStringToSlug(str) {
      if (!str || typeof str !== "string") return;

      return StringFunction.convertToSlug(StringFunction.convertUnicode(str));
    },

    formatDate(date) {
      const d = new Date(date);

      return `${d.getDate()} thg ${d.getMonth() + 1}, ${d.getFullYear()}`;
    },
    onTitleUpdated() {
      this.titleWidth = this.$refs.title.offsetWidth;
    }
  }
};
</script>

<style lang="scss">
.card-seo {
  .preview {
    font-family: Arial, sans-serif !important;
    padding: 0 6rem;
    position: relative;
    &-container {
      margin: 0 auto;
      max-width: 100%;
      min-height: 100px;
      width: 600px;
    }
    &-src {
      position: absolute;
      right: 10px;
      bottom: 8px;
    }
    .link {
      color: #1a0dab;
      font-family: Arial, sans-serif !important;
      line-height: 1.58;
      &-sub {
        display: inline-block;
        padding: 1px 0;
        cite {
          color: #202124;
          font-size: 14px;
          font-style: normal;
          line-height: 1.3;
          padding-top: 1px;
          span {
            color: #5f6368;
          }
        }
      }
      &-title {
        font-family: Arial, sans-serif !important;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.3;
        margin-bottom: 3px;
        padding-top: 2px;
      }
    }
    .link > .snippet-title {
      visibility: hidden;
      height: 0;
      font-size: 18px;
      white-space: nowrap;
    }
    .description {
      font-family: Arial, sans-serif !important;
      line-height: 1.58;
      margin-top: 4px;
      &-content {
        .time {
          color: #70757a;
        }
      }
    }
  }
}
.vue-yoast.vue-yoast-assessor.vue-yoast-content-assessor {
  .vue-yoast-assessor-item-feedback {
    display: none;
  }
}
</style>
