<template>
  <v-card class="pa-4" flat>
    <v-card-title class="pa-0">
      <div class="font-weight-bold mb-2">Ảnh thumbnail</div>
    </v-card-title>

    <div class="img-container">
      <tp-input-image
        :acceptedFileTypes="['image/*']"
        :height="675"
        :maxFileSize="500 * 1024"
        :src.sync="post.thumbnail"
        :width="1200"
        allowCompress
        allowFileDimensionValidation
        allowFileSize
        allowFileTypeValidation
        class="flex-grow-1"
        forceType="jpeg"
        required
      ></tp-input-image>

      <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mt-0">
        <span class="black--text">
          Kích thước ảnh yêu cầu là
          <span class="font-weight-bold">1200 x 675 px</span>
        </span>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },

  computed: {
    post() {
      return this.$store.getters["POST/post"];
    }
  },

  methods: {
    async onChangeThumbnail(val) {
      this.$set(this.post, "thumbnail", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.img-container {
  width: 100%;
}
</style>
