<template>
  <div class="px-5">
    <!-- Start: Top -->
    <div class="flex-row mb-5 d-flex align-center">
      <div class="mr-5 font-weight-bold text-h5">
        {{ this.post.id ? "Cập nhật bài viết" : "Viết bài mới" }}
      </div>

      <div class="mr-4 control-status">
        <v-select
          v-model="post.status"
          :disabled="disabledPostStatus"
          :items="postStatusList"
          :menu-props="{ nudgeTop: '-40px' }"
          class="rounded-lg text-body-1"
          dense
          flat
          hide-details
          item-text="name"
          item-value="value"
          no-data-text="Không có dữ liệu"
          placeholder="Trạng thái"
          solo
        ></v-select>
      </div>

      <v-btn
        class="rounded-lg text-none font-weight-medium white"
        depressed
        @click="openModalPostPreview"
      >
        <v-icon left>mdi-eye-outline</v-icon>
        Xem trước
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn
        v-if="post.id"
        :disabled="postStatusRequest === 'loading-deletePost'"
        class="mr-4 font-weight-bold"
        color="grey darkend-1"
        depressed
        icon
        rounded
        @click="deletePost()"
      >
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>

      <v-btn
        v-if="post.id"
        :loading="
          postStatusRequest.value === 'loading-updatePost' ||
            postStatusRequest.value === 'loading-uploadThumbnail'
        "
        class="rounded-lg"
        color="primary"
        depressed
        @click="
          postStatusRequest.value === 'loading-updatePost' ||
          postStatusRequest.value === 'loading-uploadThumbnail'
            ? null
            : updatePost()
        "
      >
        Lưu
      </v-btn>

      <v-btn
        v-else
        :loading="
          postStatusRequest.value === 'loading-createPost' ||
            postStatusRequest.value === 'loading-uploadThumbnail'
        "
        class="rounded-lg"
        color="primary"
        depressed
        @click="
          postStatusRequest.value === 'loading-createPost' ||
          postStatusRequest.value === 'loading-uploadThumbnail'
            ? null
            : createPost()
        "
      >
        Thêm
      </v-btn>
    </div>
    <!-- End: Top -->
    <!-- Start: Main Content -->
    <v-form ref="form" lazy-validation @submit.prevent>
      <v-row>
        <v-col class="py-0" cols="8">
          <main-detail :model-site-configs="modelSiteConfigs"></main-detail>
        </v-col>

        <v-col class="py-0 overflow-auto scrollable" cols="4">
          <right-sidebar
            :model-site-configs="modelSiteConfigs"
            @updateModelSiteConfigs="modelSiteConfigs = $event"
          ></right-sidebar>
        </v-col>
      </v-row>
    </v-form>
    <!-- End: Main Content -->
    <!-- Start: Modal Post Preview -->
    <modal-post-preview></modal-post-preview>
    <!-- End: Modal Post Preview -->
  </div>
</template>

<script>
import { toFormData } from "@vt7/utils";
import MainDetail from "./components/MainDetail/index";
import ModalPostPreview from "./components/modal/ModalPostPreview";
import RightSidebar from "./components/RightSidebar/index";
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import {
  LIST_MODEL_TYPES,
  LIST_MODEL_SITES,
  LIST_ROLES
} from "@/core/constant";

export default {
  name: "PostPage",

  components: {
    MainDetail,
    ModalPostPreview,
    RightSidebar
  },

  data() {
    return {
      LIST_MODEL_SITES: LIST_MODEL_SITES,
      modelSiteConfigs: [],
      thinkproFrontStoreUrl: process.env.VUE_APP_THINKPRO_FRONT_STORE,
      goodspaceFrontStoreUrl: process.env.VUE_APP_GOODSPACE_FRONT_STORE
    };
  },

  computed: {
    disabledPostStatus() {
      return this.user.role.toLowerCase() === LIST_ROLES.freelancerWriter.name;
    },
    post() {
      return this.$store.getters["POST/post"];
    },
    postStatusRequest() {
      return this.$store.getters["POST/statusRequest"];
    },
    tag() {
      return this.$store.getters["TAG/tag"];
    },
    uploadedThumbnail() {
      return this.$store.getters["POST/uploadedThumbnail"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    postStatusList() {
      const { permissions } = this.user;

      return [
        { name: "Chờ duyệt", value: 0 },
        // { name: "Đã duyệt", value: 1, permission: "posts.approve" },
        { name: "Đã duyệt", value: 1 },
        { name: "Bị ẩn", value: -1 }
      ].map(status => {
        if (!status.permission) return status;

        return {
          ...status,
          disabled: !permissions.includes(status.permission)
        };
      });
    }
  },

  async created() {
    if (
      this.$route.name === "website_posts-detail" &&
      this.$route.params.postId
    ) {
      await this.$store.dispatch("POST/getPostById", this.$route.params.postId);
    }
  },

  methods: {
    async onUploadImage(imageField) {
      await this.uploadThumbnail(
        toFormData({
          file: this.post[imageField]
        })
      );

      // Set uploaded thumbnail
      if (this.postStatusRequest.value === "success-uploadThumbnail") {
        this.post[imageField] = this.uploadedThumbnail;
      } else if (this.postStatusRequest.value === "error-uploadThumbnail") {
        showModalAlertError(this, {
          title: "Lỗi tải lên ảnh đại diện",
          message: this.postStatusRequest.message
        });

        return;
      }
    },

    async createPost() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      if (this.post.thumbnail instanceof File) {
        await this.onUploadImage("thumbnail");
      }

      if (this.post.cover instanceof File) {
        await this.onUploadImage("cover");
      }

      if (this.post.cover_mobile instanceof File) {
        await this.onUploadImage("cover_mobile");
      }

      const objSender = JSON.parse(JSON.stringify(this.post));

      // Create tags
      objSender.tags = await this.createTags(objSender.tags);
      this.post.tags = objSender.tags;
      // Map tags to id arrays
      objSender.tags = objSender.tags.map(tag => tag.id);

      // Format published at
      objSender.published_at = this.formatDateTime(objSender.published_at);

      objSender.widgets.rating = 5;

      // Request create
      await this.$store.dispatch("POST/createPost", objSender);
      // Alert and replace route
      if (this.postStatusRequest.value === "success-createPost") {
        if (!this.modelSiteConfigs.filter(item => item.model_id).length) {
          this.modelSiteConfigs = this.modelSiteConfigs.map(item => ({
            ...item,
            model_id: this.post.id
          }));
        }

        await this.$store.dispatch("MODEL_SITE_CONFIG/updateModelSiteConfigs", {
          data: this.modelSiteConfigs,
          modelId: this.post.id,
          modelType: LIST_MODEL_TYPES.POST
        });

        await this.$router.replace({
          name: "website_posts-detail",
          params: {
            postId: this.post.id
          }
        });

        await this.$store.dispatch(
          "POST/getPostById",
          this.$route.params.postId
        );

        showToastActionAlert(this, { message: "Thêm bài viết thành công" });
      } else if (this.postStatusRequest.value === "error-createPost") {
        showModalAlertError(this, {
          title: "Lỗi tạo mới bài viết",
          message: this.postStatusRequest.message.message
        });
      }
    },

    async createTags(tags) {
      const res = await Promise.all(
        tags.map(async tag => {
          if (typeof tag === "string") {
            await this.$store.dispatch("TAG/createTag", {
              name: tag
            });
            return this.tag;
          } else return tag;
        })
      );

      return res;
    },

    async deletePost() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa bài viết <strong>"${this.post.title}"</strong> của <strong>${this.post.created_user_name}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.$store.dispatch("POST/deletePost", {
                data: this.post.id,
                routeQuery: {
                  search: null,
                  cur_page: 1,
                  per_page: 50
                }
              });
              if (this.postStatusRequest.value === "success-deletePost") {
                await this.$router.push({ name: "website_posts" });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              }
            }
          }
        }
      });
    },

    formatDateTime(date) {
      const dateTime = new Date(date),
        hours = dateTime
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = dateTime
          .getMinutes()
          .toString()
          .padStart(2, "0"),
        day = dateTime
          .getDate()
          .toString()
          .padStart(2, "0"),
        month = (dateTime.getMonth() + 1).toString().padStart(2, "0"),
        year = dateTime.getFullYear();

      return `${year}-${month}-${day} ${hours}:${mins}:00`;
    },

    openModalPostPreview() {
      this.$modal.show({ name: "modal-post-preview" });
    },

    async updatePost() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      if (this.post.thumbnail instanceof File) {
        await this.onUploadImage("thumbnail");
      }

      if (this.post.cover instanceof File) {
        await this.onUploadImage("cover");
      }

      if (this.post.cover_mobile instanceof File) {
        await this.onUploadImage("cover_mobile");
      }

      const objSender = JSON.parse(JSON.stringify(this.post));

      // Create tags
      objSender.tags = await this.createTags(objSender.tags);
      this.post.tags = objSender.tags;
      // Map tags to id arrays
      objSender.tags = objSender.tags.map(tag => tag.id);

      // Format published at
      objSender.published_at = this.formatDateTime(objSender.published_at);

      // Request update
      await this.$store.dispatch("POST/updatePost", objSender);
      // Alert
      if (this.postStatusRequest.value === "success-updatePost") {
        await this.$store.dispatch("MODEL_SITE_CONFIG/updateModelSiteConfigs", {
          data: this.modelSiteConfigs,
          modelId: this.post.id,
          modelType: LIST_MODEL_TYPES.POST
        });

        await this.$store.dispatch(
          "POST/getPostById",
          this.$route.params.postId
        );

        showToastActionAlert(this, { message: "Đã lưu cập nhật" });
      } else if (this.postStatusRequest.value === "error-updatePost") {
        showModalAlertError(this, {
          title: "Lỗi cập nhật bài viết",
          message: this.postStatusRequest.message?.message
        });
      }
    },

    async uploadThumbnail(formData) {
      if (!formData) return;

      await this.$store.dispatch("POST/uploadThumbnail", formData);
    }
  }
};
</script>

<style lang="scss" scoped>
.control-status {
  width: 136px;
}
</style>
