<template>
  <v-card class="px-5 pt-5 pb-6" flat>
    <div class="font-weight-bold">Thời gian đăng</div>
    <div class="d-flex align-center mt-4">
      <div>
        <tp-input-date
          custom-class="text-body-1 mt-0"
          custom-style="width: 150px"
          dense
          hide-details
          outlined
          prepend-inner-icon="mdi-calendar"
          placeholder="Chọn ngày"
          :value="post.published_at"
          @change="post.published_at = $event"
        ></tp-input-date>
      </div>
      <div>
        <tp-input-time
          custom-class="text-center text-body-1 ml-3 mt-0"
          custom-style="width: 65px"
          dense
          hide-details
          outlined
          :value="publishTime"
          @change="publishTime = $event"
        ></tp-input-time>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    post() {
      return this.$store.getters["POST/post"];
    },
    publishTime: {
      get() {
        return this.getTime(this.post.published_at);
      },
      set(val) {
        this.post.published_at = this.setTime(
          this.post.published_at,
          val
        ).toISOString();
      }
    }
  },
  methods: {
    getTime(date) {
      const dateTime = new Date(date),
        hours = dateTime
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = dateTime
          .getMinutes()
          .toString()
          .padStart(2, "0");

      return `${hours}:${mins}`;
    },

    setTime(date, time) {
      let dateTime = new Date(date);
      const [hours, mins] = time.split(":");

      dateTime.setHours(parseInt(hours));
      dateTime.setMinutes(parseInt(mins));

      return dateTime;
    }
  }
};
</script>

<style lang="scss" scoped></style>
