<template>
  <div>
    <card-general></card-general>

    <card-thumbnail
      class="mt-5"
      @updateFormDataThumbnail="$emit('updateFormDataThumbnail', $event)"
    ></card-thumbnail>

    <card-cover
      class="mt-5"
      @updateFormDataCover="$emit('updateFormDataCover', $event)"
      @updateFormDataCoverMobile="$emit('updateFormDataCoverMobile', $event)"
    ></card-cover>

    <card-categories class="mt-5"></card-categories>

    <!-- Start: Tags -->
    <v-card class="px-5 pt-5 pb-1 mt-5" flat>
      <form-site-model
        v-model="computedModelSiteConfigs"
        :model-id="post.id"
        :model-type="LIST_MODEL_TYPES.POST"
        :post="post"
      ></form-site-model>
    </v-card>
    <!-- End: Tags -->

    <!-- Start: Tags -->
    <v-card class="px-5 pt-5 pb-1 mt-5" flat>
      <div class="font-weight-bold mb-2">Tag</div>
      <tp-combobox
        v-model="post.tags"
        deletable-chips
        hide-selected
        :items="tags"
        item-text="name"
        item-value="id"
        multiple
        placeholder="Nhập tag"
        small-chips
      >
      </tp-combobox>
    </v-card>
    <!-- End: Tags -->

    <!-- Start: Priority -->
    <v-card class="px-5 pt-5 pb-1 mt-5" flat>
      <div class="font-weight-bold mb-2">Ưu tiên hiển thị</div>
      <tp-select
        v-model="post.priority"
        :items="[1, 2, 3, 4, 5, 6]"
        placeholder="Nhập ưu tiên hiển thị"
        type="number"
      ></tp-select>
    </v-card>
    <!-- End: Priority -->

    <card-related-posts v-if="false" class="mt-5"></card-related-posts>

    <card-publish-time class="mt-5"></card-publish-time>
  </div>
</template>

<script>
import CardCategories from "./components/CardCategories.vue";
import CardCover from "./components/CardCover";
import CardGeneral from "./components/CardGeneral.vue";
import CardPublishTime from "./components/CardPublishTime";
import CardRelatedPosts from "./components/CardRelatedPosts";
import CardThumbnail from "./components/CardThumbnail";
import FormSiteModel from "./components/FormSiteModel";
import { LIST_MODEL_TYPES } from "@/core/constant";

export default {
  props: {
    modelSiteConfigs: {
      type: Array,
      default: () => []
    }
  },

  components: {
    CardCategories,
    CardCover,
    CardGeneral,
    CardPublishTime,
    CardRelatedPosts,
    CardThumbnail,
    FormSiteModel
  },

  data() {
    return {
      LIST_MODEL_TYPES
    };
  },

  computed: {
    computedModelSiteConfigs: {
      get() {
        return this.modelSiteConfigs;
      },
      set(val) {
        this.$emit("updateModelSiteConfigs", val);
      }
    },
    post() {
      return this.$store.getters["POST/post"];
    },
    tags() {
      return this.$store.getters["TAG/allTags"];
    }
  },

  watch: {
    "post.id": {
      async handler(val, oldVal) {
        if (!oldVal && val && this.modelSiteConfigs.length === 0) {
          const res = await this.$store.dispatch(
            "MODEL_SITE_CONFIG/getModelSiteConfigsByModelId",
            {
              modelId: val,
              modelType: LIST_MODEL_TYPES.POST
            }
          );

          this.$emit("updateModelSiteConfigs", [...res]);
        }
      },
      immediate: true
    }
  },

  async created() {
    if (this.tags.length === 0) {
      await this.$store.dispatch("TAG/getAllTags");
    }
  }
};
</script>
