<template>
  <v-card class="pa-4" flat>
    <div class="d-flex align-center justify-space-between mb-2">
      <div class="font-weight-bold">Ảnh bìa</div>
      <v-radio-group
        v-model="coverDevice"
        class="mt-0 pt-0"
        dense
        hide-details
        row
      >
        <v-radio
          class="mr-0"
          off-icon="mdi-cellphone"
          on-icon="mdi-cellphone"
          value="MOBILE"
        ></v-radio>
        <v-radio
          class="mr-0"
          off-icon="mdi-monitor"
          on-icon="mdi-monitor"
          value="DESKTOP"
        ></v-radio>
      </v-radio-group>
    </div>

    <tp-input-image
      v-if="coverDevice === 'DESKTOP'"
      :acceptedFileTypes="['image/*']"
      :height="280 * 2"
      :maxFileSize="500 * 1024"
      :src.sync="post.cover"
      :width="1200 * 2"
      allowCompress
      allowFileDimensionValidation
      allowFileSize
      allowFileTypeValidation
      class="flex-grow-1"
      forceType="jpeg"
    ></tp-input-image>

    <tp-input-image
      v-else
      :acceptedFileTypes="['image/*']"
      :height="180 * 2"
      :maxFileSize="300 * 1024"
      :src.sync="post.cover_mobile"
      :width="375 * 2"
      allowCompress
      allowFileDimensionValidation
      allowFileSize
      allowFileTypeValidation
      class="flex-grow-1"
      forceType="jpeg"
    ></tp-input-image>

    <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mt-0">
      Kích thước ảnh khuyến nghị cho
      <span class="font-weight-bold">desktop là 2400 x 560 px</span>
      cho
      <span class="font-weight-bold">mobile là 750 x 360 px</span>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      coverDevice: "MOBILE"
    };
  },

  computed: {
    post() {
      return this.$store.getters["POST/post"];
    }
  }
};
</script>

<style lang="scss" scoped>
.img-container {
  width: 100%;
}
</style>
