<template>
  <div>
    <!-- Start: Content -->
    <card-content :model-site-configs="modelSiteConfigs"></card-content>
    <!-- End: Content -->

    <!-- Start: Qna -->
    <card-qna class="mt-5"></card-qna>
    <!-- End: Qna -->

    <!-- Start: SEO Info -->
    <card-seo class="mt-5"></card-seo>
    <!-- End: SEO Info -->
  </div>
</template>

<script>
import CardContent from "./components/CardContent";
import CardSeo from "./components/CardSeo";
import CardQna from "./components/CardQna.vue";

export default {
  components: {
    CardContent,
    CardSeo,
    CardQna
  },

  props: {
    modelSiteConfigs: {
      type: Array,
      default: () => []
    }
  }
};
</script>
