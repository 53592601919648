<template>
  <v-card class="px-5 pt-5 pb-3" flat>
    <v-card-title class="font-weight-bold pa-0 mb-4"
      >Thông tin chung</v-card-title
    >
    <div>
      <div class="font-weight-bold mb-2">Tiêu đề</div>
      <tp-text-field
        v-model="post.title"
        counter
        maxlength="120"
        placeholder="Nhập tiêu đề"
        validate="required|max:120"
      ></tp-text-field>
    </div>

    <div>
      <div class="font-weight-bold mb-2">Mô tả</div>
      <tp-textarea
        v-model="post.description"
        placeholder="Nhập mô tả"
        rows="3"
        validate="required|max:600"
      ></tp-textarea>
    </div>

    <div>
      <div class="font-weight-bold mb-2">Kiểu bài viết</div>
      <tp-select
        v-model="post.type"
        :items="POST_TYPE"
        item-text="name"
        item-value="value"
        placeholder="Chọn kiểu bài viết"
        validate="required"
      ></tp-select>
    </div>
  </v-card>
</template>

<script>
import { POST_TYPE } from "@/modules/Website/constants";

export default {
  computed: {
    post() {
      return this.$store.getters["POST/post"];
    }
  },

  data() {
    return {
      POST_TYPE
    };
  }
};
</script>

<style lang="scss" scoped>
.btn-generate {
  cursor: pointer;
  transition: all 0.4s ease;
}
</style>
