<template>
  <v-card class="px-5 pt-5 pb-4" flat>
    <v-card-title class="font-weight-bold pa-0 mb-4"
      >Question and Answer</v-card-title
    >
    <v-checkbox
      v-model="post.is_qna"
      label="Thêm QnA cho bài viết"
    ></v-checkbox>

    <div v-if="post.is_qna">
      <draggable v-model="faqs" v-bind="dragOptions" handle=".handle">
        <div v-for="(qna, index) in faqs" :key="qna.id">
          <v-row>
            <v-col class="flex" cols="11">
              <icon-drag class="mt-8 mr-3 handle"></icon-drag>
              <div class="flex-1">
                <div class="font-weight-bold">Câu hỏi</div>
                <tp-text-field
                  v-model="qna.question"
                  validate="required"
                ></tp-text-field>

                <div class="font-weight-bold">Câu trả lời</div>
                <tp-textarea
                  v-model="qna.answer"
                  rows="3"
                  validate="required"
                ></tp-textarea>
              </div>
            </v-col>

            <v-col cols="1">
              <v-btn
                class="mt-6 ml-3 red lighten-5"
                color="red"
                icon
                @click="handleRemoveQuestion(qna.id)"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider v-if="index < faqs.length - 1"></v-divider>
        </div>
      </draggable>

      <v-btn
        color="primary"
        depressed
        outlined
        small
        @click="handleAddQuestion"
      >
        <v-icon left>mdi-plus</v-icon>
        Thêm câu hỏi</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import IconDrag from "@/core/assets/icons/IconDrag.vue";

export default {
  name: "CardQna",

  components: {
    draggable,
    IconDrag
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },

    post() {
      return this.$store.getters["POST/post"];
    },

    faqs: {
      get() {
        const { faqs } = this.post;
        return faqs;
      },
      set(newfaqs) {
        this.$store.commit("POST/SET_POST", { ...this.post, faqs: newfaqs });
      }
    }
  },

  methods: {
    getUniqueId() {
      return Math.floor(Math.random() * 1000000) + "-" + Date.now();
    },

    handleAddQuestion() {
      const newQuestion = { id: this.getUniqueId(), question: "", answer: "" };

      this.faqs.push(newQuestion);
    },

    handleRemoveQuestion(id) {
      this.faqs = this.faqs.filter(item => item.id !== id);
    }
  }
};
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #0065ee;
}

.handle {
  cursor: pointer;
}
</style>
