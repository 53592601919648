<template>
  <v-menu v-model="menu" :close-on-content-click="false" nudge-top="-40px">
    <template v-slot:activator="{ on, attrs }">
      <tp-combobox
        v-model="selectedItems"
        v-bind="attrs"
        v-on="on"
        deletable-chips
        small-chips
        multiple
        item-text="name"
        placeholder="Chọn folder"
        :validate="validate"
      >
      </tp-combobox>
    </template>

    <v-card class="category-tree__content">
      <v-treeview
        open-all
        shaped
        hoverable
        activatable
        color="primary"
        dense
        :items="postCategoriesTreeList"
      >
        <template #prepend="{item}">
          <v-checkbox
            v-model="selectedItems"
            class="text-body-1 mt-0"
            dense
            hide-details="auto"
            :value="item"
          ></v-checkbox>
        </template>
      </v-treeview>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "CategoriesMultipleSelect",

  props: {
    validate: {
      type: [String, Object],
      default: ""
    },
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      menu: false
    };
  },

  computed: {
    postCategoriesTreeList() {
      return this.$store.getters["POST_CATEGORY/postCategoriesTreeList"];
    },

    selectedItems: {
      get() {
        const arr = [];

        const recursion = categories => {
          categories.forEach(item => {
            if (this.value.includes(item.id)) {
              arr.push(item);
            }

            if (!item.children || item.children.length === 0) return;

            recursion(item.children);
          });
        };

        recursion(this.postCategoriesTreeList);

        return arr;
      },
      set(val) {
        const selectedItemIds = val.map(item => item.id);

        this.$emit("input", selectedItemIds);
      }
    }
  }
};
</script>
<style lang="scss">
.category-tree__content {
  height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
