<template>
  <v-card class="px-5 pt-5 pb-6" flat>
    <div class="font-weight-bold">Bài viết liên quan</div>
    <div>
      <!-- Start: Search box -->
      <v-autocomplete
        class="tp-filter-autocomplete mt-4"
        :items="filterSearchPosts"
        clearable
        dense
        deletable-chips
        hide-selected
        hide-details
        item-text="title"
        item-value="id"
        no-data-text="Không có dữ liệu"
        no-filter
        outlined
        prepend-inner-icon="mdi-magnify"
        single-line
        placeholder="Tìm kiếm bài viết"
        return-object
        :search-input.sync="postSearchKey"
        @change="selectPost($event)"
      >
        <template v-slot:item="data">
          <v-list-item-content :title="data.item.title">
            <v-list-item-title
              class="font-weight-medium"
              v-text="data.item.title"
            ></v-list-item-title>
            <div class="grey--text text--darken-2 text-subtitle-2">
              <span
                class="item-subtitle"
                v-text="data.item.created_user_name"
              ></span>
            </div>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <!-- End: Search box -->
      <!-- Start: Search box -->
      <div>
        <div class="font-weight-medium mt-4">
          Đã chọn ({{ post.related_posts ? post.related_posts.length : 0 }})
        </div>
        <v-list class="py-0">
          <v-list-item
            class="tp-border-thin rounded px-3 mt-2"
            v-for="(item, index) in post.related_posts"
            :key="index"
          >
            <v-list-item-content class="py-2">
              <v-list-item-title class="font-weight-medium">
                {{ item.title }}
              </v-list-item-title>
              <div class="grey--text text--darken-2 text-subtitle-2">
                <span
                  class="item-subtitle"
                  v-text="item.created_user_name"
                ></span>
              </div>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="removeSelectedPost(index)">
                <v-icon color="grey lighten-1">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      postSearchKey: null
    };
  },
  computed: {
    filterSearchPosts() {
      if (this.post.related_posts) {
        const arr = this.post.related_posts.map(item => item.id);

        return this.searchPosts.filter(item => !arr.includes(item.id));
      } else {
        return this.searchPosts;
      }
    },
    post() {
      return this.$store.getters["POST/post"];
    },
    searchPosts() {
      return this.$store.getters["POST/posts"];
    }
  },
  watch: {
    postSearchKey(val) {
      if (val && val.length > 0) {
        this.$store.dispatch("POST/getPosts", {
          search: val,
          per_page: 100,
          cur_page: 1
        });
      }
    }
  },
  methods: {
    removeSelectedPost(index) {
      this.post.related_posts.splice(index, 1);
    },

    selectPost(item) {
      if (item && item.id) {
        this.post.related_posts.unshift({ ...item });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
