<template>
  <tp-modal fullscreen max-width="960px" name="modal-post-preview" width="70%">
    <v-card flat>
      <v-card-title class="justify-end">
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-container class="post-container pt-0 pb-10 px-4">
        <h1 class="post-title text-center mb-6">{{ post.title }}</h1>

        <div v-if="post.thumbnail" class="post-author text-center mb-3">
          <span>
            By
            <a class="primary--text">{{
              post.id ? post.created_user_name : user.name
            }}</a>
            <br />
            {{ formatDateTime(post.published_at) }}
          </span>
        </div>

        <p class="post-thumbnail mb-6">
          <v-img
            :aspect-ratio="1000 / 450"
            :src="previewImage"
            alt="Ảnh đại diện"
          >
            <template v-slot:placeholder>
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular
                  color="grey lighten-2"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </p>

        <div class="post-main">
          <div
            class="post-main--content mb-10"
            v-html="this.post.content"
          ></div>

          <div class="post-main--tags">
            <label>Tags:</label>
            <h3
              v-for="(item, index) in this.post.tags"
              :key="index"
              class="tag-item"
            >
              <a>{{ item.id ? item.name : item }}</a>
            </h3>
          </div>
        </div>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  data() {
    return {
      defaultThumbnail: require("@/core/assets/images/default-image.png")
    };
  },
  computed: {
    post() {
      return this.$store.getters["POST/post"];
    },

    previewImage() {
      if (this.post.thumbnail instanceof File) {
        const imageSrc = URL.createObjectURL(this.post.thumbnail);
        return imageSrc;
      } else if (typeof this.post.thumbnail === "string")
        return this.post.thumbnail;
      else {
        return this.defaultThumbnail;
      }
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-post-preview" });
    },

    formatDateTime(date) {
      const dateTime = new Date(date),
        hours = dateTime
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = dateTime
          .getMinutes()
          .toString()
          .padStart(2, "0"),
        secs = dateTime
          .getSeconds()
          .toString()
          .padStart(2, "0"),
        day = dateTime
          .getDate()
          .toString()
          .padStart(2, "0"),
        month = (dateTime.getMonth() + 1).toString().padStart(2, "0"),
        year = dateTime.getFullYear();

      return `${year}-${month}-${day} ${hours}:${mins}:${secs}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.post {
  &-container {
    color: rgb(23, 28, 39);
    font-family: sans-serif !important;
    max-width: 1030px;
    @media (max-width: 991px) {
      max-width: 768px;
    }
  }

  &-title {
    font-size: 20px;
    line-height: 1.4;
  }

  &-author {
    font-size: 14px;
  }

  &-thumbnail {
    background-color: #f7f7f7;

    img {
      width: 100%;
    }
  }

  &-main {
    margin: 0 auto;
    max-width: 656px;

    &--content {
      ::v-deep {
        p {
          font-size: 16px !important;
        }
      }
    }

    &--tags {
      label {
        font-size: 18px;
        font-weight: 600;
        margin: 5px 16px 0 0;
      }

      .tag-item {
        background-color: #f3f5f8;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        height: 36px;
        line-height: 36px;
        margin: 0 8px 8px 0;
        padding: 0 15px;

        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
