<template>
  <v-card class="px-5 pt-5 pb-3" flat>
    <v-card-title class="font-weight-bold pa-0 mb-4">Danh mục</v-card-title>
    <div>
      <div class="font-weight-bold mb-2">Set on</div>
      <categories-single-select
        v-model="post.folder_id"
        validate="required"
      ></categories-single-select>
    </div>
    <div>
      <div class="font-weight-bold mb-2">List on</div>
      <categories-multiple-select
        v-model="post.folder_on_ids"
      ></categories-multiple-select>
    </div>
  </v-card>
</template>

<script>
import CategoriesSingleSelect from "@/modules/Website/components/CategoriesSingleSelect.vue";
import CategoriesMultipleSelect from "@/modules/Website/components/CategoriesMultipleSelect.vue";

export default {
  components: {
    CategoriesSingleSelect,
    CategoriesMultipleSelect
  },

  computed: {
    post() {
      return this.$store.getters["POST/post"];
    },
    postCategories() {
      return this.$store.getters["POST_CATEGORY/allPostCategories"];
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-generate {
  cursor: pointer;
  transition: all 0.4s ease;
}
</style>
