<template>
  <v-card class="px-5 pt-5 pb-4" flat>
    <v-card-title class=" pa-0 mb-4 d-flex">
      <span class="font-weight-bold">Nội dung</span>

      <image-processing-guide></image-processing-guide>
    </v-card-title>

    <ThinkTiptap
      v-model="post.content"
      :config="textEditorConfig"
      :rating.sync="post.widgets.rating"
      :skuIds.sync="post.widgets.sku_ids"
      class="tp-tiptap"
    ></ThinkTiptap>

    <tp-text-field
      v-model="post.content"
      class="post-content__text-field"
      validate="required"
    ></tp-text-field>
  </v-card>
</template>

<script>
import "lazysizes";
import ImageProcessingGuide from "@/core/components/general/ImageProcessingGuide/index.vue";

export default {
  components: { ImageProcessingGuide },

  props: {
    modelSiteConfigs: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      config: {
        baseURL: process.env.VUE_APP_THINKPRO_FRONT_STORE,
        imageUploadFolder: "social/articles",
        sites: "this.sitesConfig"
      }
    };
  },

  computed: {
    post() {
      return this.$store.getters["POST/post"];
    },

    sitesConfig() {
      return this.modelSiteConfigs
        .filter(site => site.available)
        .map(site => site.site);
    },

    textEditorConfig() {
      return {
        baseURL: process.env.VUE_APP_THINKPRO_FRONT_STORE,
        imageUploadFolder: "social/articles",
        sites: this.sitesConfig
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.tp-tiptap {
  max-height: 700px;
  resize: both;
  overflow: auto;
}

.post-content__text-field {
  ::v-deep .v-input__slot {
    display: none;
  }

  ::v-deep .v-text-field__details {
    margin-top: 8px;
    margin-bottom: 0;
  }
}
</style>
